import React, { useState } from "react";
import UserDropdown from "./UserDropdown";
import { postFeedbackDetail } from "../../services/api";
import { useRecoilValue } from "recoil";
import { dropdownOption } from "../../states/state";
import Lottie from "lottie-react";
import submitAnimation from "../../assets/Animation/submit.json";
import { Modal } from "react-bootstrap"; // Import Bootstrap Modal

export default function UserFormPage() {
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rating] = useState("3");
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Get department value from Recoil
  const selectedDepartment = useRecoilValue(dropdownOption);

  // Get user full name from sessionStorage
  const userFullName = sessionStorage.getItem("admin_name") || "";

  const handleCheckboxChange = () => {
    setIsAnonymous(!isAnonymous);
  };

  const handleSubmit = async () => {
    const trimmedFeedback = feedback.trim(); // Trim whitespace from feedback

    if (!trimmedFeedback || !selectedDepartment) {
      setModalMessage("Please fill in all required fields.");
      setShowModal(true);
      return;
    }

    const userData = {
      name: isAnonymous ? "Anonymous" : userFullName,
      department: selectedDepartment,
      feedback: trimmedFeedback,
      rating: rating,
    };

    setSubmitting(true);
    setSubmitSuccess(false);

    try {
      await postFeedbackDetail(userData);
      setSubmitSuccess(true);

      // Clear the form fields after successful submission
      setFeedback("");
      setIsAnonymous(false);

      setTimeout(() => {
        setSubmitting(false);
        setSubmitSuccess(false);
      }, 3000);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setSubmitSuccess(false);
      setSubmitting(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="userpage-container">
      <div className="userpage-main">
        <div className="navbar-header">VeSense</div>
        <div className="userpage-body">
          <div className="userpage-body-header">
            <h2>Feedback Us</h2>
            <p>We value your feedback. Please fill out the form below.</p>
          </div>
          <div className="userpage-form">
            <div>
              {/* Dropdown to select department */}
              <UserDropdown />
            </div>
            <div className="userpage-checkbox">
              <input
                type="checkbox"
                checked={isAnonymous}
                onChange={handleCheckboxChange}
                className="checkbox-box"
              />
              <label className="checkbox-label">Make Anonymous</label>
            </div>

            {!isAnonymous && (
              <>
                <label>Full Name</label>
                <input
                  className="userpage-input"
                  type="text"
                  value={userFullName}
                  readOnly
                />
              </>
            )}

            <label>Feedback *</label>
            <textarea
              className="userpage-textarea"
              placeholder="Share your thoughts with us"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
          </div>
          <div className="userpage-buttons">
            <button className="userpage-button submit" onClick={handleSubmit}>
              Submit
            </button>
          </div>

          {submitting && (
            <div className="animation-overlay">
              <Lottie animationData={submitAnimation} loop={false} />
            </div>
          )}

          {submitSuccess && (
            <div className="success-message">
              Feedback submitted successfully!
            </div>
          )}

          {/* Bootstrap Modal */}
          <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={closeModal}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
